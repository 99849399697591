import {
  PROFILE_ID,
  USER_ID,
  NOSOLOGIA_ID,
  PROJECT_ID,
  PRJ_NOS_ID,
  USER_PR_NOS_ID,
} from '@/store/const/path'

export const routeParams = {
  computed: {
    profileId() {
      return Number(this.$route.params[PROFILE_ID])
    },
    nosologiaId() {
      return Number(this.$route.params[NOSOLOGIA_ID])
    },
    userId() {
      return Number(this.$route.params[USER_ID])
    },
    projectId() {
      return Number(this.$route.params[PROJECT_ID])
    },
    projectNosologiaId() {
      return Number(this.$route.params[PRJ_NOS_ID])
    },
    userProjectNosologiaId() {
      return Number(this.$route.params[USER_PR_NOS_ID])
    },
  },
}
