import {
  COMPONENT_PATHS,
  CRITERIA,
  NOSOLOGIA_SHORT,
  PROJECT_SHORT,
  PROJECTS,
  NOSOLOGIA_ID,
  PROJECT_ID,
  SELECT_NOSOLOGIES,
  SELECT_GVS,
  SELECT_NMIC,
  SELECT_CRITERIA_PASS,
  USER_PR_NOS,
  USER_PR_NOS_ID,
} from '@/store/const/path'
import { pathConstructor } from '@/lib/pathConstructor'

const projectPaths = pathConstructor(
  COMPONENT_PATHS[PROJECTS].path,
  PROJECT_SHORT,
  PROJECT_ID
)

const projectNosologiaPath =
  projectPaths.current + `/${NOSOLOGIA_SHORT}-:${NOSOLOGIA_ID}`

export const projectRoutes = [
  {
    path: projectPaths.base,
    name: PROJECTS,
    component: () => import(`@/views/entities/ProjectsPage.vue`),
  },
  {
    path: projectPaths.current,
    name: 'Project',
    component: () => import(`@/views/entities/ProjectPage.vue`),
  },
  {
    path: projectPaths.edit,
    name: 'EditProjectPage',
    component: () => import('@/views/forms/EditProjectPage.vue'),
  },
  {
    path: projectPaths.add,
    name: 'AddProjectPage',
    component: () => import('@/views/forms/AddProjectPage.vue'),
  },
  {
    path: projectPaths.current + '/' + SELECT_NOSOLOGIES,
    name: 'SelectNosologies',
    component: () => import('@/views/in-project-pages/SelectNosologies.vue'),
  },
  {
    path: projectPaths.current + `/${USER_PR_NOS}-:${USER_PR_NOS_ID}`,
    name: 'Answers',
    component: () => import('@/views/in-project-pages/UserAnswers.vue'),
  },
  {
    path: projectNosologiaPath + '/' + CRITERIA,
    name: 'EditCriteria',
    component: () => import('@/views/in-project-pages/EditCriteria.vue'),
  },
  {
    path: projectNosologiaPath + '/' + SELECT_GVS,
    name: 'SelectGVS',
    component: () => import('@/views/in-project-pages/SelectGVS.vue'),
  },
  {
    path: projectNosologiaPath + '/' + SELECT_NMIC,
    name: 'SelectNMIC',
    component: () => import('@/views/in-project-pages/SelectNMIC.vue'),
  },
  {
    path: projectNosologiaPath + '/' + SELECT_CRITERIA_PASS,
    name: 'SelectCriteriaPassPage',
    component: () =>
      import('@/views/in-project-pages/SelectCriteriaPassPage.vue'),
  },
]
