import {
  COMPONENT_PATHS,
  LOGIN,
  RESET_PASSWORD,
  REGISTRATION,
} from '@/store/const/path'

export const loginRoutes = [
  {
    path: COMPONENT_PATHS[LOGIN].path,
    name: LOGIN,
    component: () => import(`@/views/login/Login.vue`),
  },
  {
    path: COMPONENT_PATHS[RESET_PASSWORD].path,
    name: RESET_PASSWORD,
    component: () => import(`@/views/login/ResetPassword.vue`),
  },
  {
    path: COMPONENT_PATHS[REGISTRATION].path,
    name: REGISTRATION,
    component: () => import(`@/views/login/Registration.vue`),
  },
]
