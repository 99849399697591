import { ADD, EDIT } from '@/store/const/path'

export function pathConstructor(base, short, id) {
  const current = base + '/' + short + '-:' + id
  return {
    base,
    current,
    add: base + '/' + ADD,
    edit: current + '/' + EDIT,
  }
}
