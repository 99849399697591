import {
  REGION,
  COMPONENT_PATHS,
  USER_PR_NOS,
  USER_PR_NOS_ID,
} from '@/store/const/path'

export const regionRoutes = [
  {
    path: COMPONENT_PATHS[REGION].path,
    name: REGION,
    component: () => import('@/views/region/RegionHomePage'),
  },
  {
    path: `${COMPONENT_PATHS[REGION].path}/${USER_PR_NOS}-:${USER_PR_NOS_ID}/`,
    name: 'RegionNosologiaPage',
    component: () => import('@/views/region/RegionNosologiaPage'),
  },
]
