import { COMPONENT_PATHS, USERS, USER_SHORT, USER_ID } from '@/store/const/path'

import { pathConstructor } from '@/lib/pathConstructor'

const usersPaths = pathConstructor(
  COMPONENT_PATHS[USERS].path,
  USER_SHORT,
  USER_ID
)
export const userRoutes = [
  {
    path: usersPaths.base,
    name: USERS,
    component: () => import(`@/views/entities/Users.vue`),
  },
  {
    path: usersPaths.current,
    name: 'User',
    component: () => import(`@/views/entities/UserPage.vue`),
  },
  {
    path: usersPaths.add,
    name: 'AddUserPage',
    component: () => import(`@/views/forms/AddUserPage.vue`),
  },
  {
    path: usersPaths.edit,
    name: 'EditUserPage',
    component: () => import(`@/views/forms/EditUserPage.vue`),
  },
]
