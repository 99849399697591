export default {
  state: {
    projectSearch: '',
  },
  getters: {
    getProjectSearch: state => state.projectSearch,
  },
  mutations: {
    setProjectSearch(state, payload) {
      state.projectSearch = payload
    },
  },
}
