import axios from 'axios'
import { GET, POST, DELETE, PATCH, PUT } from '@/store/const/apiMethods'
import Vue from 'vue'

function doAxios(method, url, data) {
  const apiURL = '/api/' + url
  const token = localStorage.getItem('token')
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token

  if (method === GET || !method) return axios.get(apiURL)
  if (method === POST) return axios.post(apiURL, data)
  // special way to send axios delete request with body
  if (method === DELETE && data) return axios.delete(apiURL, { data })
  if (method === DELETE) return axios.delete(apiURL)
  if (method === PATCH) return axios.patch(apiURL, data)
  if (method === PUT) return axios.put(apiURL, data)
}

// Базовый класс ошибок
export class InternalServerError extends Error {
  constructor(message, response, toast) {
    super(message)
    this.name = 'InternalServerError'
    this.response = response
    if (toast) Vue.$toast.error(message)
  }
}
export class ServerIsDeadError extends InternalServerError {
  constructor(message, response, toast) {
    super(message, response)
    this.name = 'ServerIsDeadError'
    if (toast) Vue.$toast.warning(message)
  }
}

export default async function callApi(method, url, data) {
  try {
    const res = await doAxios(method, url, data)
    return res.data
  } catch (err) {
    if (err.response.status === 500)
      throw new InternalServerError(
        'Внутренняя ошибка сервера',
        err.response,
        true
      )
    else if (err.response.status > 500)
      throw new ServerIsDeadError(
        `Технические работы на сервере\nПопробуйте обновить страницу.\nЕсли ошибка повторится зайдите позже\n[${err.response.status} - ${err.response.statusText}]`,
        err.response,
        true
      )
    throw err
  }
}
