<template>
  <v-app>
    <AppBar />
    <v-main class="mt-4 pb-16">
      <Bread v-if="isAuthenticated" class="pl-4 mb-4" />
      <CenterContent>
        <div v-if="isServerOnline">
          <router-view />
        </div>
        <div v-else>
          <v502 />
        </div>
      </CenterContent>
    </v-main>
    <v-footer app color="blue lighten-5">
      <CenterContent>
        <div class="body-2 my-1">
          &copy;&nbsp;2021&nbsp;ФГБУ «Центр экспертизы и&nbsp;контроля качества
          медицинской помощи» Минздрава России
        </div>
      </CenterContent>
    </v-footer>
  </v-app>
</template>

<script>
import CenterContent from '@/components/common/CenterContent'
import AppBar from '@/components/common/AppBar'
import Bread from '@/components/common/Bread'
import v502 from '@/views/502'
import { mapGetters } from 'vuex'

export default {
  components: {
    CenterContent,
    AppBar,
    Bread,
    v502,
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'isServerOnline']),
  },
}
</script>
