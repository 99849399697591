import {
  GVS,
  COMPONENT_PATHS,
  USER_PR_NOS,
  USER_PR_NOS_ID,
} from '@/store/const/path'

export const gvsRoutes = [
  {
    path: COMPONENT_PATHS[GVS].path,
    name: GVS,
    component: () => import('@/views/gvs/GvsHomePage'),
  },
  {
    path: `${COMPONENT_PATHS[GVS].path}/${USER_PR_NOS}-:${USER_PR_NOS_ID}/`,
    name: 'GvsNosologiaPage',
    component: () => import('@/views/gvs/GvsNosologiaPage'),
  },
]
