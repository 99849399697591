import callApi from '@/lib/callAPI'
import { GET } from '@/store/const/apiMethods'
import {
  API_USERS,
  API_PROFILES,
  API_PROJECTS,
  API_NOSOLOGIES,
} from '@/store/const/apiPaths'

export default {
  state: {
    users: [],
    profiles: [],
    projects: [],
    nosologies: [],
  },
  getters: {
    getUsersNew: state => state.users,
    getProfilesNew: state => state.profiles,
    getProjectsNew: state => state.projects,
    getNosologiesNew: state => state.nosologies,
    getAmount: state => ({
      users: state.users.length,
      profiles: state.profiles.length,
      projects: state.projects.length,
      nosologies: state.nosologies.length,
    }),
    getEntityNameByIdNew: state => (entity, id) => {
      try {
        const fieldName = entity === 'users' ? 'fio' : 'name'
        const obj = state[entity].find(e => e.id === id)
        return obj ? obj[fieldName] : false
      } catch (err) {
        console.error(err)
        return false
      }
    },
  },
  mutations: {
    setUsers(state, payload) {
      state.users = payload
    },
    setProfiles(state, payload) {
      state.profiles = payload
    },
    setProjects(state, payload) {
      state.projects = payload
    },
    setNosologies(state, payload) {
      state.nosologies = payload
    },
  },
  actions: {
    async loader({ commit }, { url, mutation }) {
      try {
        const res = await callApi(GET, url)
        commit(mutation, res)
      } catch (e) {
        commit(mutation, [])
        throw e
      }
    },
    loadProfiles({ dispatch }) {
      dispatch('loader', { url: API_PROFILES, mutation: 'setProfiles' })
    },
    loadUsers({ dispatch }) {
      dispatch('loader', { url: API_USERS, mutation: 'setUsers' })
    },
    loadProjects({ dispatch }) {
      dispatch('loader', { url: API_PROJECTS, mutation: 'setProjects' })
    },
    loadNosologies({ dispatch }) {
      dispatch('loader', { url: API_NOSOLOGIES, mutation: 'setNosologies' })
    },
  },
}
