import {
  NMIC,
  COMPONENT_PATHS,
  USER_PR_NOS,
  USER_PR_NOS_ID,
} from '@/store/const/path'

export const nmicRoutes = [
  {
    path: COMPONENT_PATHS[NMIC].path,
    name: NMIC,
    component: () => import('@/views/nmic/NmicHomePage'),
  },
  {
    path: `${COMPONENT_PATHS[NMIC].path}/${USER_PR_NOS}-:${USER_PR_NOS_ID}/`,
    name: 'NmicNosologiaPage',
    component: () => import('@/views/nmic/NmicNosologiaPage'),
  },
]
