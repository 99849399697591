import { COMPONENT_PATHS, ADMIN } from '@/store/const/path'

import { projectRoutes } from '@/router/adminRoutes/projects'
import { nosologiaRoutes } from '@/router/adminRoutes/nosologies'
import { profileRoutes } from '@/router/adminRoutes/profiles'
import { userRoutes } from '@/router/adminRoutes/users'

export const adminRoutes = [
  ...projectRoutes,
  ...nosologiaRoutes,
  ...profileRoutes,
  ...userRoutes,
  {
    path: COMPONENT_PATHS[ADMIN].path,
    name: ADMIN,
    component: () => import(`@/views/admin/AdminHomePage.vue`),
  },
]
