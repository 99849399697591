<template>
  <div class="bc-container">
    <div v-if="loading">Загружаю данные для меню...</div>
    <div v-if="loadingError">Ошибка загрузки данных для меню навигации.</div>
    <div v-if="loaded">
      <v-breadcrumbs
        v-if="breadcrumbs"
        :items="breadcrumbs"
        divider=">"
        large
        class="pa-0"
      ></v-breadcrumbs>
      <div v-else class="red--text">
        Не получилось построить меню для пути {{ $route.path }} —
        {{ errorMessage }}
      </div>
    </div>
  </div>
</template>

<script>
import {
  ADMIN,
  USERS,
  PROFILES,
  PROJECTS,
  NOSOLOGIES,
  PROFILE_SHORT,
  COMPONENT_PATHS,
  NOSOLOGIA_SHORT,
  PROJECT_SHORT,
  USER_SHORT,
  PRJ_NOS_SHORT,
  ADD,
  EDIT,
  USER_PR_NOS,
} from '@/store/const/path'
import { mapGetters } from 'vuex'
import { routeParams } from '@/mixins/routeParams'
import { USER_ADMIN_ID } from '@/store/const/userRoles'

export default {
  data() {
    return {
      loading: false,
      loadingError: false,
      loaded: false,
      errorMessage: '',
      entityDict: {
        [PROFILE_SHORT]: { name: 'profiles', parentUrl: PROFILES },
        [NOSOLOGIA_SHORT]: { name: 'nosologies', parentUrl: NOSOLOGIES },
        [USER_SHORT]: { name: 'users', parentUrl: USERS },
        [PROJECT_SHORT]: { name: 'projects', parentUrl: PROJECTS },
      },
      pathDict: {
        [USERS]: {
          [ADD]: 'Добавить пользователя',
          [EDIT]: 'Редактировать пользователя',
        },
        [PROJECTS]: {
          [ADD]: 'Добавить проект',
          [EDIT]: 'Редактировать проект',
        },
        [PROFILES]: {
          [ADD]: 'Добавить профиль',
          [EDIT]: 'Редактировать профиль',
        },
        [NOSOLOGIES]: {
          [ADD]: 'Добавить заболевание (состояние)',
          [EDIT]: 'Редактировать заболевание (состояние)',
        },
      },
    }
  },
  created() {
    this.load()
  },
  mixins: [routeParams],
  computed: {
    ...mapGetters(['getEntityNameByIdNew', 'getLoggedUser']),
    breadcrumbs() {
      try {
        const bc = this.$route.path
          .split('/')
          .filter(path => path !== '')
          .map(path => this.getOneBreadcrumb(path))
        if (bc.length <= 1) return []
        bc[bc.length - 1].disabled = true
        return bc
      } catch (e) {
        console.error(e)
        this.setErrorMessage(e)
        return null
      }
    },
  },
  methods: {
    load() {
      if (this.getLoggedUser.role_id !== USER_ADMIN_ID) {
        this.loaded = true
        return
      }

      this.loading = true
      Promise.all([
        this.$store.dispatch('loadUsers'),
        this.$store.dispatch('loadProfiles'),
        this.$store.dispatch('loadProjects'),
        this.$store.dispatch('loadNosologies'),
      ])
        .then(
          () => (this.loaded = true),
          () => (this.loadingError = true)
        )
        .finally(() => (this.loading = false))
    },
    setErrorMessage(e) {
      this.errorMessage = e.message
    },
    getEntityUrl(short, id) {
      const parent = this.entityDict[short].parentUrl
      return `/${ADMIN}/${parent}/${short}-${id}`
    },
    getOneBreadcrumb(path) {
      const obj = { exact: true, disabled: false }

      if (COMPONENT_PATHS[path]) {
        obj.text = COMPONENT_PATHS[path].getText()
        obj.to = COMPONENT_PATHS[path].path
        return obj
      }

      if (path === ADD || path === EDIT) {
        for (let key in this.pathDict) {
          if (this.$route.path.includes(key)) {
            obj.text = this.pathDict[key][path]
            return obj
          }
        }
      }

      const short = path.split('-')[0]
      const entity = this.entityDict[short]

      const id = Number(path.split('-')[1])

      if (entity) {
        obj.text = this.getEntityNameByIdNew(entity.name, id)
        obj.to = this.getEntityUrl(short, id)

        /*
          Из редактирования деталей заболевания внутри проекта —
          не стоит отправлять на редактирование заболевания в справочнике
          Это может запутать пользователя!
          Можно только вернуться ко всему проекту
          */
        if (
          entity.name === 'nosologies' &&
          this.$route.path.includes(PROJECTS)
        ) {
          obj.disabled = true
        }
        return obj
      }

      if (short === USER_PR_NOS) {
        if (this.$route.path.includes(ADMIN)) obj.text = 'Просмотр ответов'
        else obj.text = 'Страница ответов на одно заболевание (состояние)'
        return obj
      }

      if (short === PRJ_NOS_SHORT) {
        obj.text = 'Оценка выбранного заболевания (состояния)'
        return obj
      }
    },
  },
}
</script>

<style scoped>
.bc-container {
  min-height: 24px;
}
</style>
