import Vue from 'vue'
import Vuex from 'vuex'

import login from '@/store/login'
import list from '@/store/list'
import search from '@/store/search'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    login,
    list,
    search,
  },
})
