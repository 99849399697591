import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Clipboard from 'v-clipboard'

import Toast, { TYPE } from 'vue-toastification'
import 'vue-toastification/dist/index.css'
const toastOptions = {
  // default toast options here
  timeout: false,
  toastDefaults: {
    // ToastOptions object for each type of toast
    [TYPE.SUCCESS]: {
      timeout: 4000,
      hideProgressBar: true,
      closeButton: false,
    },
  },
}

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')

Vue.use(Toast, toastOptions)
Vue.use(Clipboard)
