import { adminRoutes } from '@/router/adminRoutes'
import { gvsRoutes } from '@/router/gvsRoutes'
import { nmicRoutes } from '@/router/nmicRoutes'
import { regionRoutes } from '@/router/regionRoutes'

import { COMPONENT_PATHS, ADMIN, GVS, NMIC, REGION } from '@/store/const/path'

function _mapRouteNames(list) {
  return list.map(r => r.name)
}

export const USER_HIDE_ADMIN_LOGIN = 'admin'

export const USER_ADMIN_ID = 1
export const USER_GVS_ID = 2
export const USER_NMIC_ID = 3
export const USER_REGION_ID = 4

export const USER_ROLES = {
  [USER_ADMIN_ID]: {
    id: USER_ADMIN_ID,
    homepage: COMPONENT_PATHS[ADMIN].path,
    availablePages: _mapRouteNames(adminRoutes),
    display: 'ЦЭККМП',
    db: 'admin',
    creatableByAdmin: true,
  },
  [USER_GVS_ID]: {
    id: USER_GVS_ID,
    homepage: COMPONENT_PATHS[GVS].path,
    availablePages: _mapRouteNames(gvsRoutes),
    display: 'ГВС',
    db: 'user_gvs',
    creatableByAdmin: true,
    excelHeaders: [
      'Критерий',
      'Критерий как в приказе 203н',
      'Специализированная, в том числе ВМП (стационар и дневной стационар)',
      'Первичная медико-санитарная (амбулаторные условия и дневной стационар)',
      'Тип критерия',
      'Необходимость',
    ],
  },
  [USER_NMIC_ID]: {
    id: USER_NMIC_ID,
    homepage: COMPONENT_PATHS[NMIC].path,
    availablePages: _mapRouteNames(nmicRoutes),
    display: 'НМИЦ',
    db: 'user_nmic',
    creatableByAdmin: true,
    excelHeaders: [
      'Критерий',
      'Критерий как в приказе 203н',
      'Необходимость',
      'Доступность',
      'Целесообразность',
    ],
  },
  [USER_REGION_ID]: {
    id: USER_REGION_ID,
    homepage: COMPONENT_PATHS[REGION].path,
    availablePages: _mapRouteNames(regionRoutes),
    display: 'Регион',
    db: 'user_region',
    creatableByAdmin: false,
    excelHeaders: [
      'Критерий',
      'Критерий как в приказе 203н',
      'Необходимость',
      'Доступность',
    ],
  },
}
