import {
  COMPONENT_PATHS,
  PROFILES,
  PROFILE_SHORT,
  PROFILE_ID,
} from '@/store/const/path'

import { pathConstructor } from '@/lib/pathConstructor'

const profilesPaths = pathConstructor(
  COMPONENT_PATHS[PROFILES].path,
  PROFILE_SHORT,
  PROFILE_ID
)

export const profileRoutes = [
  {
    path: profilesPaths.base,
    name: PROFILES,
    component: () => import(`@/views/entities/Profiles.vue`),
  },
  {
    path: profilesPaths.add,
    name: 'AddProfilePage',
    component: () => import('@/views/forms/AddProfilePage.vue'),
  },
  {
    path: profilesPaths.current,
    name: 'Profile',
    component: () => import('@/views/entities/Profile.vue'),
  },
  {
    path: profilesPaths.edit,
    name: 'EditProfilePage',
    component: () => import('@/views/forms/EditProfilePage.vue'),
  },
]
