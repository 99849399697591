<template>
  <v-app-bar
    app
    dense
    hide-on-scroll
    class="elevation-4"
    color="blue lighten-4"
  >
    <div @click="goHome" class="cursor">
      Оценка критериев качества медицинской помощи
    </div>
    <v-spacer></v-spacer>
    <div v-if="loggedUser.fio">
      <span class="mr-2 font-weight-bold">{{ roleName }}:</span>
      <span class="mr-4">{{ loggedUser.fio }}</span>
    </div>
    <div v-else>Не авторизован</div>
    <v-icon class="cursor" title="Выход" @click="logout"
      >mdi-exit-to-app</v-icon
    >
  </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex'
import { USER_ROLES } from '@/store/const/userRoles'
import { COMPONENT_PATHS, LOGIN } from '@/store/const/path'

export default {
  computed: {
    ...mapGetters({ loggedUser: 'getLoggedUser' }),
    roleId() {
      return this.loggedUser.role_id
    },
    roleName() {
      return USER_ROLES[this.roleId].display
    },
    homepages() {
      return Object.values(USER_ROLES).map(r => r.homepage)
    },
  },
  methods: {
    goHome() {
      if (!this.roleId) this.$router.push(COMPONENT_PATHS[LOGIN].path)
      else if (this.homepages.includes(this.$route.path)) this.$router.go()
      else this.$router.push(USER_ROLES[this.roleId].homepage)
    },
    logout() {
      localStorage.clear()
      this.$router.push(COMPONENT_PATHS[LOGIN].path)
    },
  },
}
</script>

<style scoped>
.cursor {
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.2s ease;
}
.cursor:hover {
  opacity: 0.8;
}
</style>
