import Vue from 'vue'
import VueRouter from 'vue-router'

import { loginRoutes } from '@/router/loginRoutes'
import { adminRoutes } from '@/router/adminRoutes'
import { gvsRoutes } from '@/router/gvsRoutes'
import { nmicRoutes } from '@/router/nmicRoutes'
import { regionRoutes } from '@/router/regionRoutes'

import { LOGIN, RESET_PASSWORD, REGISTRATION } from '@/store/const/path'
import { USER_ROLES } from '@/store/const/userRoles'

import store from '@/store'

import { InternalServerError } from '@/lib/callAPI'

Vue.use(VueRouter)

const routes = [
  ...adminRoutes,
  ...gvsRoutes,
  ...loginRoutes,
  ...nmicRoutes,
  ...regionRoutes,
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

// Не просим авторизацию если пользователь хочет: сбросить пароль зарегистрироваться, 502
const routesBeforeAuth = [RESET_PASSWORD, REGISTRATION, '502']

router.beforeEach(async (to, from, next) => {
  const { name } = to
  // Отправляем сразу без проверки авторизации
  if (routesBeforeAuth.includes(name)) {
    next()
    return
  }
  try {
    const { authenticated, user } = await store.dispatch('askIsAuthenticated')
    // Если не авторизован — И идет на логин или регистрацию разрешаем.
    // Если не авторизован и идет куда-то еще отправляем на логин
    if (!authenticated) {
      if (name === LOGIN) next()
      else if (name === REGISTRATION) next()
      else next({ name: LOGIN })
      return
    }
    /* 
    Если авторизован (эта проверка не нужна, т.к. не_авторизованных уже отсекли)
    Если страница не в списке доступных для роли отправляем на домашнюю
    Переход на домашнюю роли произойдет и при
    -- несуществующем адресе, т.к. его нет в списке доступных
    -- попытке перехода на корневую приложения т.к. её нет в спике доступных
    -- И при переходе на логин т.к. страницы логина нет в списке страниц доступных определенной роли
    (Сброс пароля обработали раньше)
  */
    const roleId = user.role_id
    if (!USER_ROLES[roleId].availablePages.includes(name)) {
      router.push(USER_ROLES[roleId].homepage)
      return
    }
  } catch (e) {
    if (e instanceof InternalServerError) {
      console.error('Ошибка сервера 5xx', e.response)
      // next({ name: '502' })
    }
    return // без перехода -> в никуда
  }
  // В конце концов отправить куда шел
  next()
})

export default router
