export const ADMIN = 'admin'
export const GVS = 'gvs'
export const NMIC = 'nmic'
export const REGION = 'region'
export const PROFILES = 'profiles'
export const NOSOLOGIES = 'nosologies'
export const USERS = 'users'
export const PROJECTS = 'projects'

export const LOGIN = 'login'
export const RESET_PASSWORD = 'reset-password'
export const REGISTRATION = 'registration'
export const NMIC_TOKEN = 'nmic-token'

export const PROFILE_SHORT = 'prf'
export const NOSOLOGIA_SHORT = 'nos'
export const USER_SHORT = 'us'
export const PROJECT_SHORT = 'prj'
export const PRJ_NOS_SHORT = 'q'

export const ADD = 'add'
export const EDIT = 'edit'

export const USER_PR_NOS = 'upn_answers'

export const CRITERIA = 'criteria'
export const NOS_EXPERTS = 'nos-experts'
export const SELECT_NOSOLOGIES = 'select-nosologies'
export const SELECT_GVS = 'select-gvs'
export const SELECT_NMIC = 'select-nmic'
export const SELECT_CRITERIA_PASS = 'select-criteria-pass'

export const PROFILE_ID = 'profileId'
export const USER_ID = 'userId'
export const NOSOLOGIA_ID = 'nosologiaId'
export const PROJECT_ID = 'projectId'
export const PRJ_NOS_ID = 'projectNosologiaId'
export const USER_PR_NOS_ID = 'userProjectNosologiaId'

export const COMPONENT_PATHS = {
  [LOGIN]: {
    path: `/${LOGIN}`,
    getText: () => 'Логин',
  },
  [REGISTRATION]: {
    path: `/${REGISTRATION}/:token`,
    getText: () => 'Регистрация',
  },
  [RESET_PASSWORD]: {
    path: `/${RESET_PASSWORD}/:token`,
    getText: () => 'Восстановить пароль',
  },
  [ADMIN]: {
    path: `/${ADMIN}`,
    getText: () => 'Главная',
  },
  [GVS]: {
    path: `/${GVS}`,
    getText: () => 'Главная',
  },
  [NMIC]: {
    path: `/${NMIC}`,
    getText: () => 'Главная',
  },
  [REGION]: {
    path: `/${REGION}`,
    getText: () => 'Главная',
  },
  [PROFILES]: {
    path: `/${ADMIN}/${PROFILES}`,
    getText: () => 'Профили',
  },
  [PROJECTS]: {
    path: `/${ADMIN}/${PROJECTS}`,
    getText: () => 'Проекты',
  },
  [USERS]: {
    path: `/${ADMIN}/${USERS}`,
    getText: () => 'Пользователи',
  },
  [NOSOLOGIES]: {
    path: `/${ADMIN}/${NOSOLOGIES}`,
    getText: () => 'Заболевания (состояния)',
  },
  [CRITERIA]: {
    path: null,
    getText: () => 'Выбор критериев',
  },
  [CRITERIA]: {
    path: null,
    getText: () => 'Выбор критериев',
  },
  [SELECT_NOSOLOGIES]: {
    path: null,
    getText: () => 'Выбор заболеваний (состояний)',
  },
  [SELECT_GVS]: {
    path: null,
    getText: () => 'Выбор ГВС',
  },
  [SELECT_NMIC]: {
    path: null,
    getText: () => 'Выбор НМИЦ',
  },
  [SELECT_CRITERIA_PASS]: {
    path: null,
    getText: () => 'Проверка критериев, для отправки в НМИЦ',
  },
  [NOS_EXPERTS]: {
    path: null,
    getText: () => 'Выбор экспертов',
  },
}
