import {
  COMPONENT_PATHS,
  NOSOLOGIA_SHORT,
  NOSOLOGIES,
  NOSOLOGIA_ID,
} from '@/store/const/path'

import { pathConstructor } from '@/lib/pathConstructor'

const nosologiesPaths = pathConstructor(
  COMPONENT_PATHS[NOSOLOGIES].path,
  NOSOLOGIA_SHORT,
  NOSOLOGIA_ID
)
export const nosologiaRoutes = [
  {
    path: nosologiesPaths.base,
    name: NOSOLOGIES,
    component: () => import(`@/views/entities/Nosologies.vue`),
  },
  {
    path: nosologiesPaths.current,
    name: 'Nosologia',
    component: () => import('@/views/entities/Nosologia.vue'),
  },
  {
    path: nosologiesPaths.edit,
    name: 'EditNosologiaPage',
    component: () => import('@/views/forms/EditNosologiaPage.vue'),
  },
  {
    path: nosologiesPaths.add,
    name: 'AddNosologiaPage',
    component: () => import('@/views/forms/AddNosologiaPage.vue'),
  },
]
