export const API_PROFILES = 'profiles'
export const API_USERS = 'users'
export const API_NOSOLOGIES = 'nosologies'

export const API_PROJECTS = 'projects'
export const API_PROJECTS_REPORT = 'projects/report'
export const NEXT_STAGE = 'next-stage'

export const API_REGIONS_LIST = 'regions'

export const API_CRITERIA = 'criteria'
export const API_MASS_CREATE = `${API_CRITERIA}/mass-create`

export const API_PROJECTS_NOSOLOGIES = 'projects-nosologies'
export const API_UNPIN_NOSOLOGIA = `${API_PROJECTS_NOSOLOGIES}/delete-by-identity`
export const API_PROJECTS_NOSOLOGIA_IDENTITY = `${API_PROJECTS_NOSOLOGIES}/get-by-identity`
export const API_PIN_NOSOLOGIA_WITH_GVS = `${API_PROJECTS_NOSOLOGIES}?assign-users=gvs`

export const API_AUTH = 'auth'
export const API_ASK_AUTH = `${API_AUTH}/authenticated`
export const API_AUTH_SET_PASSWORD = `${API_AUTH}/reset-password`
export const API_AUTH_GET_INFO = `${API_AUTH}/get-user-info`
export const API_LOGIN = `${API_AUTH}/login`
export const API_REGISTRATION = `${API_AUTH}/register`

export const API_USERS_PROJECT_NOSOLOGIES = 'users-projects-nosologies'

export const API_USER_CRITERIA_STAGES = 'users-criteria-stages'

export const API_CRITERIA_STAGES = 'criteria-stages'

export const API_USER_RESET_PASSWORD = 'generate-reset-password-token'
