import callApi from '@/lib/callAPI'
import { ServerIsDeadError } from '@/lib/callAPI'
import { GET } from '@/store/const/apiMethods'
import { API_ASK_AUTH } from '@/store/const/apiPaths'

export default {
  state: {
    server_alive: true,
    authenticated: false,
    loggedUser: {},
    token: '',
  },
  getters: {
    isServerOnline: state => state.server_alive,
    isAuthenticated: state => state.authenticated,
    getLoggedUser: state => state.loggedUser,
    getNmicToken: state => state.loggedUser.token,
    getToken: state => state.token,
  },
  mutations: {
    setLoggedUser(state, payload) {
      if (payload.authenticated) {
        state.authenticated = payload.authenticated
        state.loggedUser = payload.user
      } else {
        state.authenticated = false
        state.loggedUser = {}
      }
    },
    setToken(state, payload) {
      state.token = payload
    },
    setServerAlive(state, payload) {
      state.server_alive = !!payload
    },
  },
  actions: {
    async askIsAuthenticated() {
      try {
        const res = await callApi(GET, API_ASK_AUTH)
        this.commit('setLoggedUser', res)
        // при любом успешном ответе считаем что сервер живой
        this.commit('setServerAlive', true)
        return res
      } catch (err) {
        // если 500+ ошибка считаем что сервер мертв
        if (err instanceof ServerIsDeadError) {
          this.commit('setServerAlive', false)
        }
        throw err
      }
    },
    setToken(token) {
      this.commit('setToken', token)
    },
  },
}
